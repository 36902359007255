<template>
    <div class="w-full h-full">
        <section slot="pdf-content">
            <section class="pdf-item">
                <div class="ml-12 mt-4 relative w-full h-full">
                    <p class="text-md">
                        {{ $processLanguage(cityName) }}
                    </p>
                    <p class="font-blackMango lg:w-full font-bold text-base">
                        {{$processLanguage(getForm.i18n.title)}}
                    </p>
                </div>
            </section>
        </section>
        <section slot="pdf-content">
            <section class="pdf-item">
                <div class="ml-12 mt-4 lg:w-full">
                    <p class="lg:mt-0 lg:w-1/2 w-full text-lg text-bronze font-bold font-euclid">
                        {{ getPriceComputed }}
                    </p>
                </div>
            </section>
        </section>
        <section slot="pdf-content">
            <section class="pdf-item">
                <div class="w-full h-full mt-6 flex justify-center text-md">            
                    <div class="w-4/5">
                        <p class="text-justify leading-loose font-euclid tracking-widest">
                            {{ processText($processLanguage(getForm.i18n.description)) }}
                        </p>
                    </div>
                </div>
            </section>
        </section>

        <div class="html2pdf__page-break" v-if="false"/>

        <section slot="pdf-content" v-if="dividedText" class="mt-48">
            <section class="pdf-item">
                <div class="w-full h-full -mt-24 flex justify-center">            
                    <div class="w-4/5">
                        <p class="text-justify leading-loose font-euclid tracking-widest">
                            {{ divideText($processLanguage(getForm.i18n.description)) }}
                        </p>
                    </div>
                </div>
            </section>
        </section>
        <section slot="pdf-content" v-if="location != 'condominiums'">
            <section class="pdf-item">
                <div class="w-full ml-4 h-full mt-8 text-md">
                    <div class="w-1/2" style="display:block;margin-left:auto;margin-right:auto;">
                        <div class="flex md:space-x-2 space-x-1 lg:space-x-4 lg:w-1/2 w-1/2" style="margin-left:auto;margin-right:auto;">
                            <PropertyDetailsBig :number='1' img="/svg/houseDetails/rooms.svg" color="black"  class="ml-4"/>
                            <PropertyDetailsBig  :number='1' img="/svg/houseDetails/baths.svg" color="black" class="ml-4"/>
                            <PropertyDetailsBig  :number='1' img="/svg/houseDetails/garage.svg" color="black"  class="ml-4"/>
                            <PropertyDetailsBig :number='1' img="/svg/houseDetails/area.svg" color="black"  class="ml-4"/>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <section class="pdf-item" v-if="location != 'condominiums'">
            <div class="w-full ml-4 h-full mt-8">
                <div class="w-3/4" style="display:block;margin-left:auto;margin-right:auto;">
                    <div class="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-4">
                        <div class="flex justify-between" v-if="items.gross_area != '' && items.gross_area != 0">
                            <!-- Add flex before justify-between and the two are in the same line -->
                            <p class="font-bold tracking-wide">{{ $t('properties.gross_area') }}:</p>
                            <p class="text-left">{{ items.gross_area }}</p>
                        </div>
                        <div class="flex justify-between" v-if="items.bedrooms != '' && items.bedrooms != 0">
                            <p class="font-bold tracking-wide">{{ $t('properties.bedrooms') }}:</p>
                            <p class="text-left">{{ items.bedrooms }}</p>
                        </div>
                        <div class="flex justify-between" v-if="items.floor != '' && items.floor != 0">
                            <p class="font-bold tracking-wide">{{ $t('properties.floor') }}:</p>
                            <p class="text-left">{{ items.floor }}</p>
                        </div>
                        <div class="flex justify-between" v-if="items.parking != '' && items.parking != 0">
                            <p class="font-bold tracking-wide">{{ $t('properties.parking') }}:</p>
                            <p class="text-left">{{ items.parking }}</p>
                        </div>
                        <div class="flex justify-between" v-if="items.exterior_area != '' && items.exterior_area != 0">
                            <p class="font-bold tracking-wide">{{ $t('properties.util_area') }}:</p>
                            <p class="text-left">{{ items.exterior_area }}</p>   
                        </div>
                        <div class="flex justify-between" v-if="items.bathrooms != '' && items.bathrooms != 0">
                            <p class="font-bold tracking-wide">{{ $t('properties.bath') }}:</p>
                            <p class="text-left">{{ items.bathrooms }}</p>
                        </div>
                        <div class="flex justify-between" v-if="form.types.energy != '' && form.types.energy != 0">         
                            <p class="font-bold tracking-wide">{{ $t('properties.energy') }}:</p>
                            <p class="text-left">{{ processEnergyType(form.types.energy) }}</p>
                        </div>
                        <div class="flex justify-between" v-if="form.types.elevator != '' && form.types.elevator != 0">    
                            <p class="font-bold tracking-wide">{{ $t('properties.elevator') }}:</p>
                            <p class="text-left">{{ form.types.elevator }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section :class="checkOthers(divideText($processLanguage(form.i18n.description))) ? 'pdf-item mt-52' : 'pdf-item'" v-if="areThereOthers">
            <!-- begin:: listing others -->
             <div class="w-full ml-4 mt-2">
                <div class="w-3/4" style="display:block;margin-left:auto;margin-right:auto;">
                    <p class="font-bold tracking-wide">{{ $t('properties.others') }}:</p>
                    <div class="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-2 mt-2">
                        <div class="flex items-center space-x-2" v-for="(feat, index) in featuresList" :key="index">
                            <inline-svg height="0.7rem" width="0.7rem" src="/svg/check.svg" />
                            <p>{{ $processLanguage(feat) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- end:: listing others 
        </section>

        <div class="html2pdf__page-break"/>

        <section class="pdf-item">
            <div class="w-full ml-4 mt-2 mt-0">
                <div class="w-3/4" style="margin-left:auto;margin-right:auto;">
                    <div :class="'grid-cols-' + 3" class="grid gap-2 lg:gap-4">
                        <div class="md:h-30 lg:h-44 overflow-hidden rounded-lg lg:rounded-xl" 
                            v-for="item in getPropertiesMedias" :key="item.id">
                            <img @click="show(item)" 
                            class="origin-center object-center w-full md:h-20 lg:h-44 object-cover" 
                            :src="$mediaFile('properties', item.property_id , item.file)"
                            :lazy-src="$mediaFile('properties', item.property_id , item.file)">
                        </div>
                    </div>
                </div>
            </div>-->
        </section>
        <div class="html2pdf__page-break" />
    </div>
</template>
<script>
import PropertyDetailsBig from "@/components/listings/cards/PropertyDetailsBig";

export default {
    name: "PrintToPDF",
    components:{
        PropertyDetailsBig,
    },
    props: {
        items: null,
        medias: Array,
        location: String,
        ID: Number,
        cityName: null,
        form: null,
        features: null
    },
    data(){
        return{
            dividedText: false,
            textQty: 1580,
            textIncremented: 0,
            footer:{
                Phone: "(+351) 962 493 594, (+351) 966 764 147",
                Email: "agency@la-agency.com",
                Website: "www.la-agency.com",
                Address1: "Av. da Liberdade",
                Adress2: "110 1269-046 Lisbon, PT"
            }
        }
    },
    created(){        
    },
    methods:{
        processEnergyType(e){
            let isento = {
                pt: 'Isento',
                en: 'Exempt'
            }
            let processo = {
                pt: "Em Processo",
                en: "In Process"
            }

            if(e == 'Isento' || e == 'Exempt')
                return this.$processLanguage(isento)

            if(e == 'Em Processo' || e == 'In Process')
                return this.$processLanguage(processo)

            return e
        },
        processText(t){
            if(t == null)
                return ''

            if(t.length >= this.textQty){
                this.dividedText = true
                
                let incremental = 0
                for(let dotPosition = this.textQty; dotPosition < t.length; dotPosition++){
                    if(t[dotPosition] != ' ')
                        incremental++
                    else
                        break;
                }
                incremental++
                this.textIncremented = this.textQty + incremental
                return t.substr(0, this.textIncremented)
            }

            return t
        },
        divideText(t){
            if(t == null)
                return ''
            
            let incremental = 0
            for(let dotPosition = this.textIncremented; dotPosition < t.length; dotPosition++){
                if(t[dotPosition] == ' ' || t[dotPosition] == '.' || t[dotPosition] == ',')
                        incremental++
                    else
                        break;
            }
            return t.substr(this.textIncremented + incremental, t.length)
        },
        checkOthers(t){
            if(t == null)
                return false
            if(t.length >= 1530)
                return true

            return false
        }
    },
    computed:{
        getPriceComputed(){
            
            if(this.getForm.hide_price == 1)
                return this.$t('price_under_request')

            if(location != 'condominiums')
                return String(this.$currency(this.getForm.price))
            else
                return String(this.$currency(this.getForm.starting_price))
        },
        getForm(){
            return this.form
        }, 
        areThereOthers(){
            let retrievedData = this.$store.state.keysModule.key.features.data
            if(retrievedData.length == 0)
                return false

            return true
        },
        getCover(){
            let cover = null
            this.medias.forEach(element => {
                if(element.cover == 1)
                    cover = element
            });
            return cover.file
        },
        featuresList(){
            let retrievedData = this.$store.state.keysModule.key.features.data
            if(this.features == null)
                return null
            let returnedArray = []
            this.features.features_id.forEach(function(key){
                retrievedData.forEach(function(retriKey){
                    if(key == retriKey.id)
                        returnedArray.push(retriKey.name)
                })
            })
            return returnedArray
        },
        getPropertiesMedias(){
          return this.$store.state.propertiesModule.medias
        }
    }
}
</script>
