<template>
    <div class="rounded-lg border border-lightgray text-base" @click="generateLink(item.id)">
        <div class="md:flex md:items-center md:justify-between">
            <div class="flex justify-between items-end px-4 pt-3 ">
                <p class="text-bronze col-span-2 leading-none">{{item.reference}}</p>
                <div class="flex space-x-1 col-span-4 md:ml-7">
                    <PropertyDetailsBig homepage :number='item.bedrooms' img="/svg/houseDetails/rooms.svg" color="darkgray" />
                    <PropertyDetailsBig homepage :number='item.bathrooms' img="/svg/houseDetails/baths.svg" color="darkgray" />
                    <PropertyDetailsBig homepage :number='item.parking' img="/svg/houseDetails/garage.svg" color="darkgray" />
                    <PropertyDetailsBig homepage :number='item.gross_area + " " + $t("propertyCard.area")' img="/svg/houseDetails/area.svg" color="darkgray" />
                </div>
            </div>
            <div class="flex justify-between mt-5 flex px-4 md:space-x-6">
                <div>
                    <p class="uppercase text-sm text-gray tracking-widest">{{$t('developmentCard.type')}}</p>
                    <p class="mt-1">{{$processLanguage(item.type)}}</p>
                </div>
                <div>
                    <p class="uppercase text-sm text-gray tracking-widest">{{$t('developmentCard.floor')}}</p>
                    <p class="mt-1">{{item.floor}}</p>
                </div>
                <div>
                    <p class="uppercase text-sm text-gray tracking-widest">{{$t('developmentCard.exterior_area')}}</p>
                    <p class="mt-1" v-if="item.exterior_area != ''">{{item.exterior_area}} {{ $t("propertyCard.area") }}</p>
                    <p class="mt-1" v-else>-</p>
                </div>
            </div>
        </div>
        <div class="flex justify-between mt-3 flex bg-bronze items-center rounded-b-lg px-4 py-2 md:py-3">
            <div>
                <p class="font-bold text-white tracking-wider">{{$currency(item.price)}}</p>
            </div>
            <div class="flex justify-end items-center" v-if="item.attachedFile!=null">
                <inline-svg v-if="item.attachedFile!=null" fill="white" height="1.5rem" width="1.5rem" src="/svg/newDevelopments/pdf.svg" @click="downloadFile(item.id, item.attachedFile)"/>

                <p class="border border-white py-2 px-2 ml-2 text-white rounded leading-none">{{$processLanguage(item.tag)}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import PropertyDetailsBig from "@/components/listings/cards/PropertyDetailsBig";
export default {
    name: "DevelopmentsList",
    components: {PropertyDetailsBig},
    props: {
        item: Object
    },
    created(){
        //console.log(this.item)
    },
    methods:{
        async downloadFile(id, pdfFile) {
            if(pdfFile == null)
                return
            
            let myURL = this.$mediaFile('properties', 'attachments/'+id, pdfFile)
            
            await axios({
                url: myURL,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fURL = document.createElement('a');

                    fURL.href = fileURL;
                    fURL.setAttribute('download', 'file.pdf');
                    document.body.appendChild(fURL);

                    fURL.click();
            });
              return [id, pdfFile]
          },
          generateLink(id){
            this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: id }})
        },
    }
}
</script>