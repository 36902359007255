<template>
    <div class="lg:px-24 md:px-8 px-3">
        <div class="lg:w-1/2 w-full">
            <p class="font-blackMango tracking-wide text-baselg text-center lg:text-left flex items-center" v-if="location">
              <span class="text-bronze text-xl mr-2 location-bullet">&#8226;</span>
              <span>{{ location }}</span>
              <span class="font-euclid text-sm listing-ref" v-if="reference">ref. {{reference}}</span>
            </p>
            <p class="font-blackMango text-center md:w-2/3 lg:w-full inset-x-0 mx-auto lg:text-left tracking-wider font-bold text-lg mt-1">{{title}}</p>
        </div>
        <div class="flex w-full mt-3 gap-16">
          <div class="w-full lg:w-1/2 flex items-center listing-details-section gap-16 section-delimiter-bottom">
            <p  class="text-start text-lg text-bronze font-bold listing-price">
              <span v-if="price">
                {{$currency(price)}}
              </span>
              <span v-else>
                {{$t('price_under_request')}}
              </span>
            </p>
          </div>
        </div>
        
    </div>
</template>

<style scoped>
.listing-ref{
  margin-left: 10px;
  color: var(--color-gray);
  font-weight: 600;
}
.listing-price{
  line-height: initial;
  font-weight: 600;
}
.listing-details{
  gap:1rem;
  color:var(--color-darkgray);
}
.location-bullet{
  transform: translateY(-2px);
}
.section-delimiter-bottom{
  padding-bottom: 1rem;
  border-bottom: 2px solid black;
}
</style>
<script>

    export default {
        name: "TopContent",
        props: {
          price: String,
          title: String,
          location: String,
          reference: String
        },
    }
</script>