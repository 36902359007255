<template>
    <div class="rounded-xl border border-lightgray hover:border-bronze p-6 grid grid-cols-10 leading-none text-base text-center">
        <div class="grid grid-cols-4 gap-5 auto-rows-fr items-center col-span-3 justify-center">
            <p>{{item.reference}}</p>
            <p>{{$processLanguage(item.type)}}</p>
            <p>{{item.fraction}}</p>
            <p>{{item.floor}}</p>
        </div>
        <div class="grid ml-7 grid-cols-3 auto-rows-fr items-center justify-items-center col-span-2">
            <p>{{item.bedrooms}}</p>
            <p>{{item.bathrooms}}</p>
            <p>{{item.parking}}</p>
        </div>
        <div class="grid ml-10 grid-cols-3 gap-6 auto-rows-fr items-center justify-items-center col-span-3">
            <p v-if="item.gross_area != ''">{{item.gross_area}} {{ $t('propertyCard.area') }}</p>
            <p v-else>-</p>
            <p v-if="item.exterior_area != ''">{{item.exterior_area}} {{ $t('propertyCard.area') }}</p>
            <p v-else>-</p>
            <p>{{$currency(item.price)}}</p>
        </div>
        <div class="flex ml-8 col-span-2 items-center justify-end text-darkgray">
          <inline-svg v-if="item.attachedFile!=null" class="cursor-pointer fill-black w-2/5" fill="black" height="2rem" width="2rem" src="/svg/newDevelopments/pdf.svg" @click="downloadFile(item.id, item.attachedFile)"/>
          <div v-if="item.tag.indexOf('pt')>=0" @click="navigateToProperty" class="cursor-pointer w-3/5 border border-bronze text-bronze p-3 rounded-lg ml-3">{{$processLanguage(item.tag)}}</div>
          <div v-else @click="navigateToProperty" class="cursor-pointer w-3/5 border border-bronze text-bronze p-3 rounded-lg ml-3">{{item.tag}}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: "DevelopmentsCard",
    props: {
        item: Object
    },
    methods:{
        navigateToProperty(){
          this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: this.item.id }})
        },
        async downloadFile(id, pdfFile) {
            if(pdfFile == null)
                return
            
            let myURL = this.$mediaFile('properties', 'attachments/'+id, pdfFile)
            
            await axios({
                url: myURL,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fURL = document.createElement('a');

                    fURL.href = fileURL;
                    fURL.setAttribute('download', 'file.pdf');
                    document.body.appendChild(fURL);

                    fURL.click();
            });
              return [id, pdfFile]
          }
    }

}
</script>