<template>
    <div class="relative mt-6 md:mt-12 lg:mt-16 lg:px-24 md:px-8 px-3">
        <!-- begin:: order by desktop-->
        <div class="hidden lg:grid rounded-xl rounded-xl bg-black text-white py-4 px-6 grid grid-cols-10 leading-none text-base text-center">
            <div class="grid grid-cols-4 gap-5 auto-rows-fr items-center col-span-3 justify-center">
                <!-- begin:: ref-->
                <div class="flex items-center justify-center cursor-pointer">
                    {{ $t('developmentCard.reference') }}
                </div>
                <!-- end:: ref-->
                <!-- begin:: tipologia-->
                <div @click="orderByType" class="flex items-center justify-center cursor-pointer">
                    {{ $t('developmentCard.type') }}
                    <div class="ml-1">
                        <inline-svg fill="white" class="transform -rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                        <inline-svg fill="white" class="transform rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                    </div>
                </div>
                <!-- end:: tipologia-->
                 <!-- begin:: fracção-->
                <div @click="orderByUnit" class="flex items-center justify-center cursor-pointer">
                    {{ $t('developmentCard.unit') }}
                    <div class="ml-1">
                        <inline-svg fill="white" class="transform -rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                        <inline-svg fill="white" class="transform rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                    </div>
                </div>
                <!-- end:: fracção-->
                <!-- begin:: floor -->
                <div @click="orderByFloor" class="flex items-center justify-center cursor-pointer">
                    {{ $t('developmentCard.floor') }}
                    <div class="ml-1">
                        <inline-svg fill="white" class="transform -rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                        <inline-svg fill="white" class="transform rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                    </div>
                </div>
                <!-- end:: floor-->
            </div>
            <div class="grid ml-7 grid-cols-3 auto-rows-fr items-center justify-items-center col-span-2">
                <!-- begin:: rooms -->
                <div @click="orderByBedroom" class="flex items-end justify-center cursor-pointer">
                    <inline-svg fill="white" height="1.7rem" width="1.7rem" src="/svg/houseDetails/rooms.svg" />
                    <div class="ml-1">
                        <inline-svg fill="white" class="transform -rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                        <inline-svg fill="white" class="transform rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                    </div>
                </div>
                <!-- end:: rooms -->
                <!-- begin:: baths -->
                <div @click="orderByBathroom" class="flex items-end justify-center cursor-pointer">
                    <inline-svg fill="white" height="1.7rem" width="1.7rem" src="/svg/houseDetails/baths.svg" />
                    <div class="ml-1">
                        <inline-svg fill="white" class="transform -rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                        <inline-svg fill="white" class="transform rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                    </div>
                </div>
                <!-- end:: baths -->
                <!-- begin:: parking -->
                <div @click="orderByParking" class="flex items-end justify-center cursor-pointer">
                    <inline-svg fill="white" height="1.7rem" width="1.7rem" src="/svg/houseDetails/garage.svg" />
                    <div class="ml-1">
                        <inline-svg fill="white" class="transform -rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                        <inline-svg fill="white" class="transform rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                    </div>
                </div>
                <!-- end:: parking -->
            </div>
            <div class="grid ml-10 grid-cols-3 gap-6 auto-rows-fr items-center justify-items-center col-span-3">
                <!-- begin:: area -->
                <div @click="orderByArea" class="flex items-center justify-center cursor-pointer">
                    <p>{{ $t('developmentCard.gross_area') }}</p>
                    <div class="ml-1">
                        <inline-svg fill="white" class="transform -rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                        <inline-svg fill="white" class="transform rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                    </div>
                </div>
                <!-- end::area-->
                <!-- begin:: exterior area -->
                <div @click="orderByExtArea" class="flex items-center justify-center cursor-pointer">
                    <p>{{ $t('developmentCard.exterior_area') }}</p>
                    <div class="ml-1">
                        <inline-svg fill="white"  class="transform -rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                        <inline-svg fill="white" class="transform rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                    </div>
                </div>
                <!-- end:: exterior area-->
                <!-- begin:: price -->
                <div @click="orderByPrice" class="flex items-center justify-center cursor-pointer">
                    <p>{{ $t('developmentCard.price') }}</p>
                    <div class="ml-1">
                        <inline-svg fill="white" class="transform -rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                        <inline-svg fill="white" class="transform rotate-90" height="0.5rem" width="0.5rem"
                            src="/svg/arrow/arrow.svg" />
                    </div>
                </div>
                <!-- end:: price-->
            </div>
            <div class="flex col-span-2 ml-8 justify-end items-center">
                <inline-svg class="w-2/5" fill="white" height="2rem" width="2rem" src="/svg/newDevelopments/download.svg" />
                <div class="w-3/5 p-3 rounded-lg ml-3">{{ $t('developmentCard.state') }}</div>
            </div>
        </div>
        <!-- end:: order by desktop-->

        <!-- begin:: order by mobile-->
            <div class="relative text-md flex items-center block lg:hidden">
            <select class="block h-full bg-transparent appearance-none cursor-pointer">
                <option>{{ $t('developmentCard.most_recent') }}</option>
                <option>{{ $t('developmentCard.price_low') }}</option>
            </select>
            <inline-svg fill="black" class="ml-1 w-3 h-3 top-0 transform rotate-90"
                src="/svg/arrow/arrow.svg" />
        </div>
        <!-- end:: order by mobile-->

        <!-- begin:: properties desktop -->
        <div class="flex flex-col space-y-2 md:space-y-4 mt-1 md:mt-3 lg:mt-4" >
            <DevelopmentsListingCard class="hidden lg:grid" 
                v-for="(item, index) in this.propertiesList" 
                :key="index" 
                :item="item"/>
            <DevelopmentsListingCardMobile class="block lg:hidden"  
                v-for="(item, index) in this.propertiesList" 
                :key="index + 'B'"
                
                :item="item" />
        </div>
          <button v-if="listingNumberDesktop<this.listItems.length" @click="showAllProperties()" class="hover:text-bronze w-full mt-2 md:mt-6 py-1 text-sm font-bold uppercase leading-none tracking-button inset-x-0 mx-auto">
            <span>{{ $t('buttons.viewAll') }}</span>
        </button>
    </div>
</template>

<script>
    import DevelopmentsListingCard from '@/components/product/developments/DevelopmentsListingCard.vue';
    import DevelopmentsListingCardMobile from '@/components/product/developments/DevelopmentsListingCardMobile.vue';

    export default {
        name: "DevelopmentsList",
        components: {
            DevelopmentsListingCard,
            DevelopmentsListingCardMobile
        },
        props:{
            listItems: Array
        },
        data: function () {
            return {
                listingNumberMobile: 5,
                listingNumberDesktop: 8,
                incrementStep: 8,
                propertiesList: this.listItems.slice(0, this.listingNumberDesktop),
                unitOrder: false,
                floorOrder: false,
                bedroomOrder: false,
                bathroomOrder: false,
                parkingOrder: false,
                areaOrder: false,
                extAreaOrder: false,
                priceOrder: false
            }
        },
        mounted(){
            this.propertiesList = this.listItems.slice(0, this.listingNumberDesktop)
        },
        methods: {
            showAllProperties() {
                this.listingNumberDesktop += this.incrementStep
                this.propertiesList = this.listItems.slice(0, this.listingNumberDesktop)
            },
            orderByType(){
                function compareAsc( a, b ) {
                    if ( a.type.en < b.type.en ){
                        return -1;
                    }
                    if ( a.type.en > b.type.en ){
                        return 1;
                    }
                    return 0;
                }
                function compareDesc( a, b ) {
                    if ( a.type.en < b.type.en ){
                        return 1;
                    }
                    if ( a.type.en > b.type.en ){
                        return -1;
                    }
                    return 0;
                }
                if(this.typeOrder){
                    this.propertiesList = this.listItems.sort( compareDesc ).slice(0, this.listingNumberDesktop);
                    this.typeOrder = false;
                }
                else{
                    this.propertiesList = this.listItems.sort( compareAsc ).slice(0, this.listingNumberDesktop);
                    this.typeOrder = true;
                }
            },
            orderByUnit(){
                function compareAsc( a, b ) {
                    if ( a.fraction < b.fraction ){
                        return -1;
                    }
                    if ( a.fraction > b.fraction ){
                        return 1;
                    }
                    return 0;
                }
                function compareDesc( a, b ) {
                    if ( a.fraction < b.fraction ){
                        return 1;
                    }
                    if ( a.fraction > b.fraction ){
                        return -1;
                    }
                    return 0;
                }
                if(this.unitOrder){
                    this.propertiesList = this.listItems.sort( compareDesc ).slice(0, this.listingNumberDesktop);
                    this.unitOrder = false;
                }
                else{
                    this.propertiesList = this.listItems.sort( compareAsc ).slice(0, this.listingNumberDesktop);
                    this.unitOrder = true;
                }
            },
            orderByFloor(){
                function compareAsc( a, b ) {
                    if ( a.floor < b.floor ){
                        return -1;
                    }
                    if ( a.floor > b.floor ){
                        return 1;
                    }
                    return 0;
                }
                function compareDesc( a, b ) {
                    if ( a.floor < b.floor ){
                        return 1;
                    }
                    if ( a.floor > b.floor ){
                        return -1;
                    }
                    return 0;
                }
                if(this.floorOrder){
                    this.propertiesList = this.listItems.sort( compareDesc ).slice(0, this.listingNumberDesktop);
                    this.floorOrder = false;
                }
                else{
                    this.propertiesList = this.listItems.sort( compareAsc ).slice(0, this.listingNumberDesktop);
                    this.floorOrder = true;
                }
            },
            orderByBedroom(){
                function compareAsc( a, b ) {
                    if ( a.bedrooms < b.bedrooms ){
                        return -1;
                    }
                    if ( a.bedrooms > b.bedrooms ){
                        return 1;
                    }
                    return 0;
                }
                function compareDesc( a, b ) {
                    if ( a.bedrooms < b.bedrooms ){
                        return 1;
                    }
                    if ( a.bedrooms > b.bedrooms ){
                        return -1;
                    }
                    return 0;
                }
                if(this.bedroomOrder){
                    this.propertiesList = this.listItems.sort( compareDesc ).slice(0, this.listingNumberDesktop);
                    this.bedroomOrder = false;
                }
                else{
                    this.propertiesList = this.listItems.sort( compareAsc ).slice(0, this.listingNumberDesktop);
                    this.bedroomOrder = true;
                }
            },
            orderByBathroom(){
                function compareAsc( a, b ) {
                    if ( a.bathrooms < b.bathrooms ){
                        return -1;
                    }
                    if ( a.bathrooms > b.bathrooms ){
                        return 1;
                    }
                    return 0;
                }
                function compareDesc( a, b ) {
                    if ( a.bathrooms < b.bathrooms ){
                        return 1;
                    }
                    if ( a.bathrooms > b.bathrooms ){
                        return -1;
                    }
                    return 0;
                }
                if(this.bathroomOrder){
                    this.propertiesList = this.listItems.sort( compareDesc ).slice(0, this.listingNumberDesktop);
                    this.bathroomOrder = false;
                }
                else{
                    this.propertiesList = this.listItems.sort( compareAsc ).slice(0, this.listingNumberDesktop);
                    this.bathroomOrder = true;
                }
            },
            orderByParking(){
                function compareAsc( a, b ) {
                    if ( a.parking < b.parking ){
                        return -1;
                    }
                    if ( a.parking > b.parking ){
                        return 1;
                    }
                    return 0;
                }
                function compareDesc( a, b ) {
                    if ( a.parking < b.parking ){
                        return 1;
                    }
                    if ( a.parking > b.parking ){
                        return -1;
                    }
                    return 0;
                }
                if(this.parkingOrder){
                    this.propertiesList = this.listItems.sort( compareDesc ).slice(0, this.listingNumberDesktop);
                    this.parkingOrder = false;
                }
                else{
                    this.propertiesList = this.listItems.sort( compareAsc ).slice(0, this.listingNumberDesktop);
                    this.parkingOrder = true;
                }
            },
            orderByArea(){
                function compareAsc( a, b ) {
                    if ( a.gross_area < b.gross_area ){
                        return -1;
                    }
                    if ( a.gross_area > b.gross_area ){
                        return 1;
                    }
                    return 0;
                }
                function compareDesc( a, b ) {
                    if ( a.gross_area < b.gross_area ){
                        return 1;
                    }
                    if ( a.gross_area > b.gross_area ){
                        return -1;
                    }
                    return 0;
                }
                if(this.areaOrder){
                    this.propertiesList = this.listItems.sort( compareDesc ).slice(0, this.listingNumberDesktop);
                    this.areaOrder = false;
                }
                else{
                    this.propertiesList = this.listItems.sort( compareAsc ).slice(0, this.listingNumberDesktop);
                    this.areaOrder = true;
                }
            },
            orderByExtArea(){
                function compareAsc( a, b ) {
                    if ( a.exterior_area < b.exterior_area ){
                        return -1;
                    }
                    if ( a.exterior_area > b.exterior_area ){
                        return 1;
                    }
                    return 0;
                }
                function compareDesc( a, b ) {
                    if ( a.exterior_area < b.exterior_area ){
                        return 1;
                    }
                    if ( a.exterior_area > b.exterior_area ){
                        return -1;
                    }
                    return 0;
                }
                if(this.extAreaOrder){
                    this.propertiesList = this.listItems.sort( compareDesc ).slice(0, this.listingNumberDesktop);
                    this.extAreaOrder = false;
                }
                else{
                    this.propertiesList = this.listItems.sort( compareAsc ).slice(0, this.listingNumberDesktop);
                    this.extAreaOrder = true;
                }
            },
            orderByPrice(){
                function compareAsc( a, b ) {
                    if ( a.price < b.price ){
                        return -1;
                    }
                    if ( a.price > b.price ){
                        return 1;
                    }
                    return 0;
                }
                function compareDesc( a, b ) {
                    if ( a.price < b.price ){
                        return 1;
                    }
                    if ( a.price > b.price ){
                        return -1;
                    }
                    return 0;
                }
                if(this.priceOrder){
                    this.propertiesList = this.listItems.sort( compareDesc ).slice(0, this.listingNumberDesktop);
                    this.priceOrder = false;
                }
                else{
                    this.propertiesList = this.listItems.sort( compareAsc ).slice(0, this.listingNumberDesktop);
                    this.priceOrder = true;
                }
            },
        },
        watch:{
            propertiesList(val){
                this.propertiesList = val
            }
        }
    }
</script>