<template>
    <div class="w-full h-full">
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="isMobile"
            :preview-modal="!isMobile"
            :paginate-elements-by-height="1400"
            :filename="$processLanguage(form.i18n.title)"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-content-width="800px"
            :html-to-pdf-options="htmlToPdfOptions"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"  
        >
            <section slot="pdf-content">
                <div class="w-full h-full flex justify-center">
                    <div class="w-96 h-56 flex justify-center">
                        <img                    
                            :src="$mediaFile(location, ID, getCover)"
                            class="rounded-lg w-96 h-56 object-cover"
                        >  
                    </div>
                </div>
            </section>
            <section slot="pdf-content" class="w-auto">
                <PdfContent 
                    :items="computeItems" 
                    :medias="computeMedias"
                    :location="computeLocation"
                    :ID="computeID"
                    :cityName="computeCityName"
                    :form="computeForm"
                    :features="features"
                />
            </section>
        </vue-html2pdf>
    </div>
</template> 

<script>
//https://mycurelabs.github.io/vue-html-to-paper/ FOR PRINT
import VueHtml2pdf from 'vue-html2pdf'
import PdfContent from '@/components/printing/pdfContents/PdfContent'
 
export default {
    name: "PrintToPDF",
    components: {
        VueHtml2pdf,
        PdfContent
    },
    props:{
        printPDF: {
            Boolean,
            default: false
        },
        items: null,
        medias: Array,
        attachments: Array,
        location: String,
        ID: Number,
        cityName: null,
        form: null,
        features: null
    },
    created(){
        this.isMobile = window.innerWidth < 600
    },
    data(){
        return{
            htmlToPdfOptions: {
                margin: [1,0,0,0],

                image: {
                    type: 'jpeg', 
                    quality: 0.98
                },

                enableLinks: false,

                html2canvas: {
                    scale: 1,
                    useCORS: true
                },

                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'portrait'
                }
            },
            isMobile: false
        }
    },
    methods: {
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
        },
    },
    watch:{
        printPDF(v){
            this.printPDF = v
            if(this.printPDF)
                this.generateReport()
        }
    },
    computed:{
        computeItems(){
            return this.items
        },
        computeMedias(){
            return this.medias
        },
        computeLocation(){
            return this.location
        },
        computeID(){
            return this.ID
        },
        computeCityName(){
            return this.cityName
        },
        computeForm(){
            return this.form
        },
        getCover(){
            let cover = null

            if(this.medias == null)
                return ''

            if(this.medias.length == 0)
                return ''
            this.medias.forEach(element => {
                if(element.cover == 1){
                    cover = element
                    return cover.file
                }
            });

            if(cover == null)
                return ''
            
            return cover.file
        }
    }
}
</script>
