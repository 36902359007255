<template>
    <div class="relative lg:flex lg:space-x-12 mt-2 items-start mt-8 relative lg:px-24 md:px-8 px-3">
        <div class="lg:w-1/2 w-full">
            <!-- begin:: description-->
            <p class="text-justify leading-loose">{{description}}</p>
            <!-- begin:: book visit button-->
            <button @click="showContact()"
                class="mt-6 py-1 text-sm font-medium uppercase leading-none tracking-button inline-flex text-white items-center justify-center bg-black w-full">
                <span class="mr-2">{{ $t('buttons.bookVisit.book') }}</span>
                <div class="mt-2">
                    <inline-svg class="mb-1" fill="white" height="15" src="/svg/lock.svg" />
                </div>
                <span class="ml-2">{{ $t('buttons.bookVisit.visit') }}</span>
            </button>
            <!-- end:: book visit button-->
            <!-- end:: description-->
        </div>
        <!-- begin::gallery-->
        <div class="lg:w-1/2 w-full mt-8 lg:mt-0 lg:-top-0">
            <GalleryBigImage v-if="getImageGallery.length > 0"
                @show="showSlider()" 
                :numberImgs="4" 
                :gallery="getImageGallery"
                imageLocation="condominiums" />           
            <!-- end:: gallery -->
        </div>
    </div>
</template>

<script>
import GalleryBigImage from '@/components/product/GalleryBigImage.vue';

    export default {
        name: "MainContent",
        components:{GalleryBigImage},
        props: {
    description: String,
    gallery: Array,
  },
   data: function () {
            return {
                isModalVisible: false,
            }
        },
        created(){
        },
        methods: {
            showContact(item) {
                this.$emit('showContactModal',item);
            },
             showSlider(item) {
                this.$emit('showSliderModal',item);
            },
        },
        computed:{
            getImageGallery(){
                return this.gallery
            },
            getGalleryCount(){
                return this.gallery.length
            }
        }
    }
</script>